export const shortTitles: { [styleTitle: string]: string } = {
  'American Medical Association 11th edition': 'AMA',
  'American Psychological Association 5th edition': 'APA 5',
  'American Psychological Association 6th edition': 'APA 6',
  'American Psychological Association 7th edition': 'APA 7',
  'Modern Language Association 8th edition': 'MLA 8',
  'Modern Language Association 9th edition': 'MLA 9',
  'Oxford University Standard for Citation of Legal Authorities': 'OSCOLA',
  'Modern Humanities Research Association 4th edition (note with bibliography)':
    'MHRA 4',
};

export const getShortStyleTitle = (styleTitle: string): string => {
  if (shortTitles[styleTitle]) {
    return shortTitles[styleTitle];
  } else if (styleTitle.length > 26) {
    return styleTitle.substring(0, 23) + '...';
  } else {
    return styleTitle;
  }
};

export const getLongStyleTitle = (styleTitle: string): string => {
  return shortTitles[styleTitle]
    ? `${styleTitle} (${shortTitles[styleTitle]})`
    : styleTitle;
};
